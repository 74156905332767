import PluginCss from '!raw-loader!@egjs/react-flicking/dist/flicking.css';
import brand from '@helpers/brand';
import { Device, from, until } from '@helpers/media';
import NextButtonStyles from '@stories/Components/Buttons/NextButton/NextButton.styles';
import PrevButtonStyles from '@stories/Components/Buttons/PrevButton/PrevButton.styles';
import { FileCardStyles } from '@stories/Components/Cards/FileCard/FileCard.styles';
import HeadingStyles from '@stories/Components/Global/Typography/Heading.styles';
import styled, { css } from 'styled-components';
import { ReactComponent as svgOverlay } from '@img/banner-overlay-solid.svg';
import { ThemeSwitch } from '@helpers/theme';
const Container = styled.div `
  overflow: hidden;
  position: relative;
  margin-top: 50px;
  padding-bottom: 50px;
`;
const ImageOverlay = styled(svgOverlay) `
  position: absolute;
  bottom: 0;
  left: 0;
  transform: scaleY(-1);
  z-index: 0;
  height: 350px;
  width: 100%;
  color: ${brand.grey.grey40};
  z-index: -1;

  @media ${from(Device.TabletLarge)} {
    height: 550px;
  }
`;
const LineDrawing1 = styled.img `
  position: absolute;
  bottom: -15%;
  left: 0px;
  width: 840px;
  max-width: 100%;
  opacity: 0.1;
  object-fit: cover;

  @media ${from(Device.Tablet)} {
    bottom: -30%;
  }
`;
const LineDrawing2 = styled.img `
  position: absolute;
  width: 550px;
  bottom: -100px;
  transform: rotate(-36deg);
  opacity: 0.1;
  right: 5%;
  object-fit: cover;

  @media ${until(Device.Desktop)} {
    display: none;
  }
`;
const Carousel = styled.div `
  position: relative;
  grid-column: span 12;

  ${HeadingStyles.Heading} {
    grid-column: span 12;
  }

  ${({ center }) => center
    ? css `
          @media ${from(Device.TabletLarge)} {
            grid-column: 3 / span 8;
          }
        `
    : css `
          @media ${from(Device.TabletLarge)} {
            grid-column: 5 / span 8;
          }
        `}
`;
const Featured = styled.div `
  grid-column: span 12;

  @media ${from(Device.TabletLarge)} {
    grid-column: span 4;
  }

  ${() => Item} {
    height: 100%;
  }
`;
const Page = styled.div `
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
  gap: 30px;
  width: 100%;

  @media ${from(Device.Tablet)} {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr;
    padding: 0px 5px;
  }

  @media ${from(Device.TabletLarge)} {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
  }
`;
const ItemList = styled.div `
  --itemGutter: 10px;

  ${PluginCss};

  display: flex;
  width: 100%;

  @media ${from(Device.Tablet)} {
    --itemGutter: 30px;
  }
`;
const Item = styled.div `
  display: block;

  ${FileCardStyles.Card} {
    height: 100%;
  }
`;
const CtaWrapper = styled.div `
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin: 22px 0 0;

  @media ${from(Device.Tablet)} {
    justify-content: center;
    margin: 64px 0 0;
  }
`;
const Controls = styled.div `
  display: grid;
  grid-template-columns: 40px 40px 1fr 40px 40px;
  margin: 24px 0 0;
  gap: 12px;
  grid-column: span 12;
  z-index: 2;
  ${ThemeSwitch({ groundforceColour: brand.primary.alt })};

  @media ${from(Device.Tablet)} {
    grid-template-columns: 48px 48px 1fr 48px 48px;
    margin: 40px 0 0;
  }

  ${PrevButtonStyles.Button} {
    background-color: var(--theme);
    grid-column: 4;
  }

  ${NextButtonStyles.Button} {
    background-color: var(--theme);
    grid-column: 5;
  }
`;
const CarouselDot = styled.button.attrs({ type: 'button' }) `
  display: flex;
  flex: 1 0 auto;
  border: none;
  border-radius: 0;
  height: 5px;
  padding: 0;
  background-color: ${brand.grey.grey89};
  transform-origin: center bottom;
  transition: background-color 0.15s ease-out, transform 0.15s ease-out;
  max-width: 132px;
  ${ThemeSwitch({ groundforceColour: brand.primary.alt })};

  &:focus {
    outline: none;
  }

  &:focus,
  &:hover {
    background-color: ${brand.black};
  }

  &[disabled] {
    background-color: var(--theme);
  }
`;
const DotHolder = styled.div `
  display: flex;
  flex: 1 0 auto;
  justify-content: center;
  align-items: center;
  gap: 6px;
  grid-column: 1 / span 3;

  @media ${until(Device.TabletLarge)} {
    &[data-hidden='1'] {
      display: none;
    }
  }

  @media ${from(Device.Tablet)} {
    gap: 10px;
  }

  @media ${from(Device.TabletLarge)} {
    grid-column: 3;
  }
`;
const Base = styled.div `
  grid-column: span 12;
  padding: 30px 0;
  text-align: center;
`;
export default {
    Base,
    CarouselDot,
    Container,
    Controls,
    CtaWrapper,
    DotHolder,
    ImageOverlay,
    Carousel,
    Featured,
    Page,
    Item,
    ItemList,
    LineDrawing1,
    LineDrawing2,
};

import { HydrateOption } from '@core/enums';
import Flicking, { ALIGN, DIRECTION, } from '@egjs/react-flicking';
import { Grid } from '@helpers/grid';
import { Device, until } from '@helpers/media';
import withWidget from '@hoc/withWidget';
import useMedia from '@hooks/useMedia';
import SvgLineDrawingUrl from '@img/line-drawing-black.svg';
import { LinkButton } from '@stories/Components/Buttons/Button/Button';
import NextButton from '@stories/Components/Buttons/NextButton/NextButton';
import PrevButton from '@stories/Components/Buttons/PrevButton/PrevButton';
import FileCard from '@stories/Components/Cards/FileCard/FileCard';
import React, { useState } from 'react';
import S from './TechnicalDocumentsList.styles';
import { useDispatch, useSelector } from 'react-redux';
import { LoginRedirectType } from '@redux/ducks/app/app.reducer';
import withRedux from '@helpers/withRedux';
import { ThemeWrapper } from '@helpers/theme';
const TechnicalDocumentsList = ({ items = [], relatedFilesRedirectUrl, cta, theme, }) => {
    const refFlicking = React.useRef(null);
    const [activeIndex, setActiveIndex] = useState(0);
    const [showControls, setShowControls] = React.useState(false);
    const featured = items.find((x) => x.featured);
    const slideItems = items.filter((x) => !x.featured);
    const slidesOnPage = useMedia([until(Device.Tablet), until(Device.TabletLarge)], [1, 2], 4);
    const dispatch = useDispatch();
    const app = useSelector((x) => x.app);
    const pages = Math.ceil(slideItems.length / slidesOnPage);
    const getPageItems = (pageIndex) => {
        const startIndex = pageIndex * slidesOnPage;
        return slideItems.slice(startIndex, startIndex + slidesOnPage).map((item) => (React.createElement(S.Item, { key: item.name },
            React.createElement(FileCard, { file: {
                    ...item,
                    content: undefined,
                }, redirectUrl: relatedFilesRedirectUrl, onClick: (e) => {
                    if (!app.isLoggedIn) {
                        e.preventDefault();
                        e.stopPropagation();
                        dispatch({
                            type: 'APP/SET_LOGIN_MODAL',
                            payload: {
                                loginType: LoginRedirectType.TechnicalLibrary,
                                redirectUrl: item.url,
                            },
                        });
                    }
                } }))));
    };
    return (React.createElement(ThemeWrapper, { "data-theme": theme },
        React.createElement(S.Container, null,
            React.createElement(S.ImageOverlay, { preserveAspectRatio: "none" }),
            React.createElement(S.LineDrawing1, { src: SvgLineDrawingUrl, loading: "lazy", role: "presentation" }),
            React.createElement(S.LineDrawing2, { src: SvgLineDrawingUrl, loading: "lazy", role: "presentation" }),
            React.createElement(Grid.Default, null,
                featured && (React.createElement(S.Featured, null,
                    React.createElement(S.Item, { "data-featured": true },
                        React.createElement(FileCard, { file: featured, redirectUrl: relatedFilesRedirectUrl, onClick: (e) => {
                                if (!app.isLoggedIn) {
                                    e.preventDefault();
                                    e.stopPropagation();
                                    dispatch({
                                        type: 'APP/SET_LOGIN_MODAL',
                                        payload: {
                                            loginType: LoginRedirectType.TechnicalLibrary,
                                            redirectUrl: featured.url,
                                        },
                                    });
                                }
                            } })))),
                React.createElement(S.Carousel, { center: !featured },
                    React.createElement(S.ItemList, null,
                        React.createElement(Flicking, { ref: refFlicking, align: ALIGN.PREV, useFindDOMNode: true, 
                            // bound
                            onAfterResize: async (event) => {
                                // Move to first item after resizing to fix misalignment.
                                event.currentTarget.moveTo(0);
                                const visiblePanels = getVisiblePanels(event.currentTarget);
                                // Hide controls when all panels are visible.
                                setShowControls(!(visiblePanels.length === pages));
                            }, onChanged: (event) => {
                                // Auto-focus the new slide if the focus was already inside the carousel.
                                if (event.currentTarget.element.contains(document.activeElement)) {
                                    event.panel.element.focus();
                                }
                            }, onWillChange: (event) => {
                                if (event.index > pages - 1) {
                                    setActiveIndex(event.index - pages);
                                }
                                else {
                                    setActiveIndex(event.index);
                                }
                            }, onMoveStart: (event) => {
                                const visiblePanels = getVisiblePanels(event.currentTarget);
                                // Avoid swiping when all panels are visible.
                                if (visiblePanels.length === pages) {
                                    event.stop();
                                }
                            }, onReady: (event) => {
                                const visiblePanels = getVisiblePanels(event.currentTarget);
                                // Hide controls when all panels are visible.
                                setShowControls(!(visiblePanels.length === pages));
                            } }, new Array(pages).fill(null).map((x, index) => (React.createElement(S.Page, { key: index }, getPageItems(index))))))),
                showControls && pages > 1 && (React.createElement(S.Controls, null,
                    React.createElement(S.DotHolder, { "data-hidden": pages > 5 ? '1' : '0' }, new Array(pages).fill(null).map((item, index) => (React.createElement(S.CarouselDot, { key: index, disabled: activeIndex === index, onClick: (event) => {
                            event.preventDefault();
                            moveTo(index);
                        } })))),
                    React.createElement(PrevButton, { disabled: activeIndex === 0, themeOption: "primaryAlt", onClick: (event) => {
                            event.preventDefault();
                            refFlicking.current?.prev();
                        } }),
                    React.createElement(NextButton, { disabled: activeIndex === pages - 1, themeOption: "primaryAlt", onClick: (event) => {
                            event.preventDefault();
                            refFlicking.current?.next();
                        } }))),
                cta && (React.createElement(S.Base, null,
                    React.createElement(LinkButton, { title: cta.title, target: cta.target, href: cta.url, branding: "primaryAlt" })))))));
    function moveTo(index) {
        const actualIndex = refFlicking.current?.index ?? 0;
        // Adjust index to account for cloned items.
        if (actualIndex > pages - 1) {
            index += pages;
        }
        const direction = index < actualIndex ? DIRECTION.PREV : DIRECTION.NEXT;
        refFlicking.current?.moveTo(index, undefined, direction);
    }
};
export default withWidget(withRedux(TechnicalDocumentsList), 'TechnicalDocumentsList', {
    hydrate: HydrateOption.InView,
});
function getVisiblePanels(instance) {
    return instance.panels.filter((panel) => panel.visibleRatio >= 0.9);
}
